<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Возврат выполнен</v-alert>
    <v-alert :value="createdError" type="error" class="my-0">
      {{ errorMessage }}
    </v-alert>
    <v-form
      ref="form"
      lazy-validation
      v-if="!loading"
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Возврат букета №{{ id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-textarea
          label="Комментарий"
          auto-grow
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.comment"
          row-height="12"
        ></v-textarea>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editedItem: {
        comment: null,
      },
      item: {},
      createdSuccess: false,
      loading: true,
      createdError: false,
      errorMessage: '',
    };
  },
  methods: {
    getItem() {
      this.loading = true;
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquets/${this.id}`;

      axios
        .get(url)
        .then((response) => {
          const item = response.data;
          this.item = item;

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.createdError = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);

        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bouquets/${this.id}/revert`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            this.createdBouquet();

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.createdError = true;
            this.errorMessage = error.response.data.error;
          });
      }
    },
    createdBouquet() {
      let cardsList = JSON.parse(localStorage.getItem('cardsList'));
      cardsList = (cardsList !== null) ? cardsList : [];
      const item = this.item;

      const itemProps = {
        client: item.client,
        clientId: item.client_id,
        decorCost: item.decor_cost,
        decorPercent: item.decor_percent,
        deliveryCost: item.delivery_cost,
        sumDecorAdditional: item.sum_decor_additional,
        floristId: item.florist_id,
        orderId: 0,
        salePercent: item.sale_percent,
        sumSale: item.sum_sale,
        totalCost: item.total_cost,
      };
      const itemGoods = item.goods.map((elem) => {
        return {
          id: elem.good_id,
          value: elem.count,
        };
      });
      const itemSum = item.goods.reduce((sum, elem) => {
        const goodSum = elem.good.price * elem.count;
        return sum + goodSum;
      }, 0);

      cardsList.push({
        sum: itemSum,
        success: false,
        props: itemProps,
        goods: itemGoods,
      });

      localStorage.setItem('cardsList', JSON.stringify(cardsList));
      this.$router.push({ path: '/', query: { selectOrder: item.id } });
    },
  },
  created() {
    this.getItem();
  },
};
</script>
