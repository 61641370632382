<template>
    <v-card>
      <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Возврат выполнен</v-alert>
      <v-form
        ref="form"
        lazy-validation
        v-if="!loading"
      >
        <v-card-title
          class="px-4"
        >
          <span class="headline">Возврат букетов</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="px-4"
        >
          <v-textarea
            label="Комментарий"
            auto-grow
            :rules="[v => !!v || 'Заполните поле']"
            v-model="editedItem.comment"
            row-height="12"
          ></v-textarea>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-btn
            @click.native="cancel()"
          >Отмена</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            @click="submitForm"
          >Сохранить</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      ids: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        editedItem: {
          comment: null,
        },
        items: [],
        createdSuccess: false,
        loading: true,
      };
    },
    methods: {
      getItems() {
        this.loading = true;
        const requests = this.ids.map(id => axios.get(`bouquets/${id}`));
        axios.all(requests)
          .then(axios.spread((...responses) => {
            this.items = responses.map(response => response.data);
            this.loading = false;
          }))
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      },
      cancel() {
        this.editedItem = {};
        this.createdSuccess = false;
        this.$emit('cancel');
      },
      submitForm() {
        const validate = this.$refs.form.validate();
        if (validate) {
          const propsItem = Object.assign({}, this.editedItem);
  
          const requests = this.ids.map(id => 
            axios.post(`/bouquets/${id}/revert`, propsItem)
          );
  
          axios.all(requests)
            .then(() => {
              this.createdSuccess = true;
              this.createdBouquets();
              setTimeout(() => {
                this.$emit('cancel');
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      createdBouquets() {
        let cardsList = JSON.parse(localStorage.getItem('cardsList')) || [];
        this.items.forEach(item => {
          const itemProps = {
            client: item.client,
            clientId: item.client_id,
            decorCost: item.decor_cost,
            decorPercent: item.decor_percent,
            deliveryCost: item.delivery_cost,
            sumDecorAdditional: item.sum_decor_additional,
            floristId: item.florist_id,
            orderId: 0,
            salePercent: item.sale_percent,
            sumSale: item.sum_sale,
            totalCost: item.total_cost,
          };
          const itemGoods = item.goods.map(elem => ({
            id: elem.good_id,
            value: elem.count,
          }));
          const itemSum = item.goods.reduce((sum, elem) => sum + (elem.good.price * elem.count), 0);
  
          cardsList.push({
            sum: itemSum,
            success: false,
            props: itemProps,
            goods: itemGoods,
          });
        });
  
        localStorage.setItem('cardsList', JSON.stringify(cardsList));
        this.$router.push({ path: '/', query: { selectOrder: this.ids.join(',') } });
      },
    },
    created() {
      this.getItems();
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles here */
  </style>
  